.upperPart {
  min-height: var(--size-50);
  height: var(--size-50);
  display: flex;
  align-items: flex-end;
  padding-left: var(--size-30);
  border-left: none;
  font-size: var(--size-14);
  gap: 50px;
}

.tabs {
  cursor: pointer;
  margin-bottom: 14px;
  text-align: center;
}

.activeTab {
  width: calc(100% + 10px);
  min-width: max-content;
  height: 4px;
  border-radius: 6px;
  background-image: linear-gradient(95deg, #6bbbe3 0%, #3e8ab0 110%);
}

.tabOptions {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.externalTab {
  margin-bottom: 10px !important;
}
