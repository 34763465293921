.layoutDropDown {
  position: absolute;
  top: 11rem;
  left: 6rem;
  z-index: 2;
  background-color: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--size-24);
  border-radius: var(--size-8);
  box-shadow: var(--size-4) var(--size-4) var(--size-15) 0 rgb(65 92 171 / 15%);
  width: var(--size-440);
  color: var(--primary-text);
}
