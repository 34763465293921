.mainContainer {
  position: absolute;
  width: 52.2%;
  height: 86.5%;
  background: var(--surface-white);
  color: var(--primary-text);
  min-width: var(--size-650);
  display: flex;
  flex-direction: column;
  z-index: 100;
  overflow: hidden;
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(25 32 72 / 50%);
  z-index: 99;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  background: var(--surface-stroke-gradient-color1);
  height: var(--size-60);
  font-size: var(--size-24);
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  padding: var(--size-12) var(--size-40);
  position: sticky;
  z-index: 10;
  top: 0;
}

.textBoxBorder {
  width: 36%;
}

.flexColumnCointainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.panelsHeader {
  display: flex;
  gap: var(--size-13);
  align-items: center;
  font-size: var(--size-16);
  font-weight: bold;
  color: var(--primary-text);
  padding-left: var(--size-28);
}

.flexCointainer {
  display: flex;
  align-items: center;
  padding: var(--size-20) 0 var(--size-20) var(--size-40);
}

.layoutName {
  padding-right: 3.2%;
}

.panelBox {
  display: flex;
  justify-content: space-between;
  padding: 0 var(--size-40);
  gap: 4%;
  color: var(--primary-text);
  font-size: var(--size-14);
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  padding-bottom: var(--size-20);
  height: 100%;
}

.panelInnerBox {
  width: 100%;
  height: 100%;
  padding-top: var(--size-27);
  display: flex;
  flex-direction: column;
  gap: var(--size-27);
}

.dndBox {
  border: 1px solid var(--surface-stroke-gradient-color1);
  box-shadow: var(--size-4) var(--size-4) var(--size-15) rgb(65 92 171 / 15%);
  border-radius: var(--size-8);
  border-style: solid;
  border-width: var(--size-1);
  border-image-source: linear-gradient(94deg, #ecf3fe 30%, rgb(236 243 254 / 15%) 111%);
  border-image-slice: 1;
  overflow: auto;
  height: 100%;
}

.saveContainer {
  display: flex;
  height: var(--size-80);
  align-items: center;
  justify-content: flex-end;
  padding-right: 4%;
  gap: 2.4%;
  padding: var(--size-10);
}

.shareContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5% var(--size-25);
  color: var(--primary-text);
  font-size: var(--size-16);
}

.button {
  height: var(--size-32);
}

.componentHeader {
  font-size: var(--size-14);
  font-weight: 600;
  padding: var(--size-4) 0;
}

.insideContent {
  display: flex;
  flex-direction: row;
  gap: var(--size-14);
  padding: var(--size-15) 0 var(--size-15) var(--size-25);
  font-size: var(--size-14);
  background: var(--surface-white);
  align-items: center;
}

.insideContentSelected {
  display: flex;
  flex-direction: row;
  gap: var(--size-14);
  padding: var(--size-15) 0 var(--size-15) var(--size-25);
  font-size: var(--size-14);
}

.availableOptionContainer {
  display: flex;
  flex-direction: column;
  padding-top: var(--size-108);
  gap: var(--size-24);
}

.optionStyle {
  height: var(--size-40);
  width: var(--size-40);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ecf3fe;
  border-radius: var(--size-4);
}

.zoomIconAndSlider {
  gap: var(--size-20);
  align-items: center;
  display: flex;
}

.zoomLevelContainer {
  display: flex;
  padding-left: var(--size-40);
  gap: var(--size-50);
  align-items: center;
  padding-top: var(--size-80);
  padding-bottom: var(--size-30);
  color: var(--primary-text);
  font-size: var(--size-16);
}

.manualZoomContainer {
  display: flex;
  align-items: center;
  gap: var(--size-32);
}

.zoomTextboxAndPercentageSign {
  display: flex;
  align-items: center;
  gap: var(--size-8);
}

.zoomTextbox {
  display: flex;
}
