/* change border color of all grids */
.e-grid .e-toolbar,
.e-grid,
.e-grid .e-rowcell,
.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse,
.e-grid .e-rowdragdropcell,
.e-grid .e-detailrowcollapse,
.e-grid .e-detailrowexpand,
.e-grid .e-detailindentcell,
.e-grid .e-detailcell,
.e-pager,
.e-grid .e-headercontent {
  border-color: var(--system-colour-44);
}

.e-grid:not(.e-childgrid) > .e-toolbar {
  border: none;
}

/* modify css of child grid */
.e-grid .e-detailcell {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 28px;
  border: none;
}

.e-detailrow .e-detailindentcell {
  border: none;
}

.e-childgrid {
  box-shadow: 4px 4px 15px 0 #415cab26;
}
