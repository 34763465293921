.e-custom-fe-updateIcon {
  background-image: url("../../assets/icons/svg/Update.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px !important;
  height: 24px;
}

.e-custom-fe-renameIcon {
  background-image: url("../../assets/icons/svg/editProfile.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px !important;
  height: 24px;
}

.e-custom-fe-deleteIcon {
  background-image: url("../../assets/icons/svg/delete.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px !important;
  height: 24px;
}

.e-custom-fe-duplicateIcon {
  background-image: url("../../assets/icons/svg/Duplicate.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px !important;
  height: 24px;
}

.e-item {
  align-items: center;
  font-size: 14px;
  color: var(--primary-text);
}
